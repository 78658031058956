%atom-image-general{
    max-width: 100%;
    img{
        max-width: 100%;
        vertical-align: middle;
    }
    figcaption{
        padding: 10px 20px;
        background-color: #ffffff;
        border: 1px solid #8f8f8f;
        @extend %text-caption-rte;
    }
    margin-bottom: 20px;

    @include RWD(mobile){
        margin-left: auto !important;
        margin-right: auto !important;
        float: none !important;
    }
}

%atom-image-original{
    width: auto;
    display: block;
    margin: 0 auto;
    margin-bottom: 20px;
}

%atom-image-full{
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    max-width: 100vw;

    figcaption{
        //@extend %container;
        position: relative;
        @include RWD(desktop){
            padding: 10px calc((100vw - 1300px) / 2);
        }
        &:before{
            background-color: #f4f4f4;
            content: '';
            display: block;
            z-index: -1;
            position: absolute;
            top: 0;
            left: -5000px;
            right: -5000px;
            height: 100%;
            @include RWD(mobile){
                left: 0;
                right: 0;
                position: static;
            }
        }
    }
}