%molecule-menu-rwd--cms{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 250;
    overflow: hidden;
    
    ul{
        padding: 80px 0 0;

        li{
            padding: 10px 20px;

            &:hover, &:focus{
                background-color: $accent-color;
            };

        }    
    }    

    & > ul {
        width: 100%;
        /* transform: translateX(-300px); */
        height: 100%;
        
        transition: all 0.5s ease;
        > li{

            &.has-submenu .sub-toggler{
                position:absolute;
                top: 0;
                right: 0;
                width: 20px;
                height: 20px;
                background-image: url(svg-bg-uri($svg-arrow-right, #fff));
                background-repeat: no-repeat;
                background-size: 12px 20px;
                background-position: 50%;
                margin-right: 10px;
            }    
            &.opened{
                .submenu{
                    transform: translateX(-351px);
                }    
            }    
            
            .submenu{
                position: absolute;
                z-index: 3;
                top: 0;
                left: 350px;
                display: block;
                height: 100%;
                width: 100%;
                background-color: #4c4c4c;
                transition: all 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
                box-shadow: -10px 0px 20px 0px rgba(0,0,0,0.2);
            }
        }

        a{
            z-index: 2;
            color: #fff;
            display: block;
            text-decoration: none;
            text-transform: uppercase;
            line-height: 20px;
            position: relative;
            font-size: 2rem;
        }
    }
}