%organism-comarquage{
    a{
        text-decoration: none;
    }
    .sommaire{
        display: flex;
        flex-wrap: wrap;

        a{
            @extend %atom-btn-int--core;
            margin-bottom: 10px;
            margin-right: 10px;

            &:last-child{
                margin-right: 0;
            }
        }
    }
    h2{
        .fa{
            margin-right: 10px;
        }
    }
    .grid-comarquage{
        @extend %molecule-grid-comarquage;
    }
}