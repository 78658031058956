%atom-hat-default{
    @extend %molecule-inlines-rte;
    @extend %text-hat;
    text-align: center;
    .container{
        @extend %container;
        max-width: 100%;
    }
    background-color: #fff;
    padding: 30px 50px;
    border:1px solid #8f8f8f;
}