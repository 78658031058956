%molecule-nav-progressbar{
    position: relative;
    @extend %container-form;

    // Gestion des ma-width en fonction du nombre d'items
    @for $i from 1 through 10{
        &[data-page="#{$i}"]{
            .webform-progressbar-page{
                max-width: #{100% / $i};
            }
        }
    }

    .webform-progressbar-outer{
        display: flex;
        justify-content: space-between;
        overflow: hidden;
    }
    
    // Barre de progression derriere les chiffres
    .webform-progressbar-wrapper{
        background-color: #edf2e7;
        position: absolute;
        top: 35px;
        left: 30px;
        width: calc(100% - 60px);
        transform: translateY(-50%);
        height: 2px;
        z-index: -1;

        @include RWD(mobile){
            // display: none;
            top: 15px;
        }

        .webform-progressbar-inner{
            height: 100%;
            background-color: $core-color-light;
        }
    }

    // %age de progression
    .webform-progressbar-number{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 30px; 

        &:after{
            display: block;
            content: ''; 
            margin-top: 10px;
            height: 1px;
            background-color: #d8d8d8;
            width: 80%;
        }
        .flexbox{
            padding: 5px;
            background-color: #f7f7f7;
            color: #9c9c9c;
        }
    }

    .webform-progressbar-page{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
        position: relative;

        &:hover, &:focus{
            text-decoration: none;
        }

        &:first-child{
            background: linear-gradient(to right, #FFFFFF 0%, #FFFFFF 50%, transparent 50%, transparent 100%);
        }
        &:last-child{
            background: linear-gradient(to left, #FFFFFF 0%, #FFFFFF 50%, transparent 50%, transparent 100%);
        }
        

        &-number{
            height: 70px;
            width: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5000px;   
            font-weight: 600;
            font-size: 16px;
            font-size: 1.6rem;    

            @include RWD(mobile){
                height: 30px;
                width: 30px;
            }     
        }
        &-label{
            text-transform: uppercase;
            font-size: 16px;
            font-size: 1.6rem;
            font-weight: 600;
            margin-top: 15px;
            padding: 0 5px;

            @include RWD(mobile){
                display: none;
            }
        }

        // DEFAULT

        &-number{
            background-color: #f7f7f7;
            color: #9c9c9c;     
        }
        &-label{
            color: #9c9c9c;
        }
 

        // CURRENT
        &.current{
            .webform-progressbar-page-number{
                background-color: $second-color;
                color: #FFFFFF;
            }
            .webform-progressbar-page-label{
                color: $second-color;
            }
        }

        // Validé
        &.completed{
            .webform-progressbar-page-number{
                background-color: $core-color;
                color: #FFFFFF;
            }
            .webform-progressbar-page-label{
                color: $core-color;
            }
        }
    }
}