%organism-footer{
    display: block;

    .container{
        display: flex;
        flex-flow: row wrap;
        padding-top: 60px;

        & > ul{
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 30px;

            & > li{
                width: calc(25% - (150px / 4));

                font-size: 1.6rem;
                line-height: 2rem;

                h3{
                    font-size: 2rem;
                    margin-bottom: 20px;
                }

                ul{
                    list-style: disc inside;
                    color: #949494;

                    li a{
                        text-decoration: none;
                        color: #201f1f;
                        &:hover, &:focus, &:active{
                            text-decoration: underline;
                        };
                    }
                }


            }
        }

        .telmedia-link{
            margin:0 0 20px auto;
            text-decoration: none;
            font-size: 1.4rem;
            svg{
                width: 75px;
                height: auto;
            }
        }
    }

    @include RWD(tablet){
        .container > ul{
            flex-wrap: wrap;

            & > li{
                width: 50%;
                margin-bottom: 30px;
            }
        }
    }

    @include RWD(mobile){
        .container > ul{
            text-align: center;

            & > li{
                width: 100%;
            }
        }
    }

    @media print {
        display: none;
    }

}