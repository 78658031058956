%molecule-highlight-content{
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    width: auto;
    background-color: $core-color;
    padding: 35px 0px;
    margin-bottom: 40px;

    .container > *{
        color: #FFFFFF;
        font-weight: bold;
        font-size: 15px;
        font-size: 1.5rem;
        line-height: 24px;
        letter-spacing: 0.05em;
        text-align: center;
        
        a{
            color: #FFFFFF;
            opacity: 1;

            &:hover, &:focus{
                color: #FFFFFF;
                opacity: 0.7;
            }
        }

        &:last-child{
            margin-bottom: 0;
        }
    }
}