// Animation au hover des vignettes (souvent déclaré dans les molecules-grid

%animation-vignette{
    background-color: #FFFFFF;

    .grid-item-visu{
        &:after{
            opacity: 1;
        }
    }
}