// BreakPoints
$break-small: 767;
$break-large: 1380; // Cette valeur doit être égale à la largeur du container + X pixel. Le passage en tablette se fera dont à X pixel de plus que la largeur du container principal, permettant de ne jamais avoir le contenu collé au bord de l'écran.
$container-width: 1300;

//~#COLORS
	$core-color: #3D7E97;
	$core-color-access: $core-color;
	$core-color-light: lighten($core-color,65);
	$second-color: #47AE9E;
	$second-color-light: lighten($second-color,65);
	$success: $core-color;   
	$error: #B21212;
	$accent-color: #8EBF27;
	$accent-color-acc: #72a135;
//#COLORS
 
// Fonts
$site-font: 'WorkSans';
$main-font: $site-font; 

/*IMAGES RTE*/
$image-xs: 240px;
$image-sm: 480px;
$image-md: 620px;

// Margin RTE
$margin-item-rte: 40px;
$margin-p: 20px;

$margin-h2: 25px;
$margin-h3: 25px;
$margin-h4: 20px;
$margin-h5: 15px;

$margin-h2-mobile: 20px;
$margin-h3-mobile: 20px;
$margin-h4-mobile: 15px;
$margin-h5-mobile: 10px;

// Map engine
$icon-color: #595959;
$icon-color-active: $second-color;