%molecule-user-alert--cms{
    .wrapper{
        @extend %container;
        display: flex;
    }
    padding: 10px 0;
    background-color: $core-color;
    position: relative;

    .close{
        display: block;
        height: 30px;
        width: 30px;
        background: url(svg-bg-uri($svg-dmnt-cross, #fff)) transparent no-repeat 50%/20px;
        flex-shrink: 0;
        transition: all, 0.5s;

        &:hover, &:focus{
            opacity: 0.5;
        }
    }

    .user-alert-message{
        flex-grow: 1;
        display:flex;
        text-decoration : none;
        &:hover, &:focus{
            p{text-decoration: underline;}
        }

        & > *{
            display: block;;
            color: #FFFFFF;
            font-size: 1.8rem;
            line-height: 2rem;
        }
        
        .user-label{
            font-size: 2.2rem;
            font-weight: 600;
            margin-right: 5px;
            text-transform: uppercase;
            flex-shrink: 0;
        }

        @include RWD(mobile){
            display: block;
            
            .user-label span{
                display: none;
            }
        }
    }

    @media print{
        display: none;
    }
}