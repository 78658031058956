.not-front{
    @extend %text-basis-rte;

    #header--cms {
        .region-main-nav{
            position: relative;
            
            @include RWD(desktop){
                &:before{
                    content: '';
                    display: block;
                    position: absolute;
                    top:290px;
                    left:0;
                    width: 100%;
                    height: 65px;
                    background: #fff;
        
                    z-index: 0;
                }
                #main-logo, {
                    margin-bottom: 50px;
                }
            }
            @include RWD(mobile){
                padding-bottom: 20px;
            }

            #main-logo{
                align-items: flex-end;
                
                @include RWD(tablet){
                    img{
                        height: 135px;
                    }
                }
                
                p{display:none;}
            }
        }

        .region-nav-collapsed{
            margin-bottom: 20px;
        }
    }
    
    h1{
        @extend %atom-main-title;
    }
    .hat{
        margin-bottom: 30px;
    }
    .region-nav-tools{
        padding-top: 5px;
        padding-bottom: 5px;
        @extend %container;
        display: flex;
        align-items: center;     
        margin-bottom: 20px;

        .block-container-breadcrumb{
            margin-right: auto;
            position: relative;
        }
    }

    // Publié le d'une actu
    .actu-published{
        text-align: right;
        font-style: italic;
        color: $core-color-access;
        font-size: 12px;
        font-size: 1.2rem;
        margin-bottom: 20px;
    }

    .slider-int{
        .owl-nav{
            height: 100%;

            .owl-prev, .owl-next{
                height: 100%;
            }
            .owl-pagination{
                display: none !important;
            }
        }
    }

    ///////////////////////////////////////////////
    // Compte perso
    // - Accueil :                                  #page-monespacepersonnel
    // - Mon profil :                               #page-monprofil
    // - Mes documents disponibles (adhérent) :     #page-mesdocumentsdisponibles
    // - Documents dématérialisés (correspondant) : #page-documentsdematerialises
    // - Statistiques (avec spé correspondant) :    #page-statistiques
    // - Suivi administratif :                      #page-suiviadministratif
    // - Petites annonces  :                        #page-petitesannonces
    // CP - Petite annonce - page annonce :         #page-petitesannonces-annonce
    // - Petites annonces - creation :              #page-petitesannonces-creation
    // - Bibliothèque :                             #page-bibliotheque


    // CP - Accueil 
    #page-monespacepersonnel{

        .account-pages-list{
            display: flex;
            flex-wrap: wrap;
            border: 1px solid #8f8f8f;

            li{
                width: 33.33333332%;
                padding: 30px;

                &:nth-child(n+4){
                    border-top: 1px solid #8f8f8f;
                }

                a{
                    color: inherit;
                    text-decoration: none;
                    &:hover, &:focus{
                        .title{
                            color: $core-color;
                        }
                    };
                }
                .visu{
                    width: 100%;
                    padding-top: 68.4%;
                    margin-bottom: 10px;
                    background-size: cover;
                }
                .title{
                    padding: 15px 0;
                    font-weight: 600;
                    font-size: 2rem;
                    line-height: 2rem;
                    color: $accent-color-acc;
                }
                .description{
                    font-size: 1.5rem;
                }
            }
        }

        @include RWD(mobile){
            .account-pages-list li{
                width: 100%;
                &:nth-child(n+4){
                    border-top: none;
                }
            }
        }


    }

    // CP - Mon profil
    #page-monprofil{

        h2{
            @extend %text-h2;
        }
        
        ul{
            padding: 25px 0;
            li{
                color: #595959;
                padding:9px 0 9px 55px;
                line-height: 2.2rem;
                position: relative;

                &:before{
                    content: '•';
                    display: block;
                    position: absolute;
                    left: 43px;
                    color: $second-color;
                    font-size: 1.8rem;

                }
            }
        }

        label{
            font-size: 1.6rem;
            font-weight: 600;
            color: #333;
            letter-spacing: 0;
        }

        section.informations{
            ul{
                display: flex;
                flex-flow: column wrap;
                height: 420px;
            }
            li{
                display: flex;
                flex-direction: row;
                width: 50%;

                label{
                    width: 180px;
                    flex-shrink: 0;
                    flex-grow: 0;
                }
                input, textarea{
                    flex-grow:1;
                }
            }
        }

        section.notifications{
            margin-bottom: 30px;

            .error{
                padding-top: 20px;
                color: $error;
            }
            .notification{
                margin-bottom: 8px;
                padding-top: 0;
                padding-bottom: 0;

                &:before{
                    line-height: 2.8rem;
                }

                label{
                    display: flex;
                    width: 725px;
                    justify-content: space-between;
                    line-height: 2.8rem;
                }
                
                input[type="checkbox"]{
                    display: none;
                }
                .fakeCheckbox{
                    position: relative;
                    display:inline-block;
                    flex-shrink: 0;
                    flex-grow: 0;
                    vertical-align: middle;
                    &:before{
                        content:'';
                        display: block;
                        width: 75px;
                        height: 28px;
                        border-radius: 14px;
                        transition: background-color 0.2s ease;
                    }
                    &:after{
                        content: '';
                        display: block;
                        height: 24px;
                        width: 24px;
                        background-color: #f0f0f0;
                        border-radius: 12px;
                        position: absolute;
                        top: 2px;
                        transition: left 0.2s ease;
                    }
                }
                input[type="checkbox"]:not(:checked) + .fakeCheckbox{
                    &:before{
                        background-color: $error;
                    }
                    &:after{
                        left:2px;
                    }
                }
                input[type="checkbox"]:checked + .fakeCheckbox{
                    &:before{
                        background-color: $second-color;
                    }
                    &:after{
                        left:49px;
                    }
                }
                input[type="checkbox"][disabled] + .fakeCheckbox{
                    &:before{
                        background-color: #595959;
                    }
                }
            }
        }

        .informations-change{
            margin: 0 calc(-50vw + 50%) 30px;
            padding: 40px 0;
            background-color: $core-color;
            text-align: center;

            p{
                display: block;
                color: #fff;
                text-align: center;
                margin-bottom: 30px;
            }
            button{
                display: inline-block;
                background-color: $second-color;
                padding: 11px 55px;
                font-size: 1.6rem;
                line-height: 2.6rem;
                color: #fff;
                border: 1px solid #fff;
            }
        }


        @include RWD(tablet){
            section.notifications .notification label{
                width: 100%;
            }
        }
        @include RWD(mobile){

            ul{
                padding: 20px 0;
                li{
                    padding:9px 0 9px 20px;
                }
            }
            section.informations ul{
                max-height: none;
                li{
                    width: 100%;
                    display: block;

                    &:before{
                        content: none;
                    }
                    & > *{
                        display: block;
                        width: 100%;
                    }
                }
            } 
            section.notifications .notification label{
                margin-bottom: 30px;
            }
        }

    }

    // CP - Mes documents disponibles (adhérent)
    #page-mesdocumentsdisponibles,
    
    // CP - Documents dématérialisés (correspondant)
    #page-documentsdematerialises{
        
        .files-sort-form{
            display:flex;
            justify-content: space-between;
            margin-bottom: 30px;
            align-items: center;
            max-width: 100%;
            
            .customSelectContain, .select2{
                flex-grow: 1;
                margin-right: 20px;
            }
            .button-files{
                flex-grow: 0;
                flex-shrink: 0;
                @extend %atom-btn-int--core;
                @extend %btn-animation;
            }

        }

        .accordion{
            width: 100%;
        }

        @include RWD(mobile){
            .files-sort-form{
                flex-wrap: wrap;

                .customSelectContain{
                    margin: 0 0 20px;
                    width: 100%;
                }

                .button-files{
                    width: 100%;
                    font-size: 2rem;
                    margin-bottom: 40px;
                }
            }
        }
    }

    // CP - Statistiques (avec spé correspondant)
    #page-statistiques{

        .title{
            border-bottom: 1px solid #8f8f8f;
            text-align: center;
            font-size: 2rem;
            font-weight: 600;
            padding: 25px 0;
            flex-grow:0;
        }
        .content{
            display: flex;
            flex-wrap: wrap;
        }
        .iframe-wrapper{
            height: 665px;
            flex: 1;
            display: flex;
            flex-direction: column;

            .iframe-container{
                width: 100%;
                height: calc(100% - 70px);
                flex-grow: 1;

                iframe{
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .files-wrapper{
            border-right: 1px solid #8f8f8f;
            flex: 1;

            .files{
                flex-grow: 1;
            }
        }

        @include RWD(tablet){
            .iframe-wrapper, .files-wrapper{
                width: 100%;
                flex: none;
            }
            .iframe-wrapper{
                border-top: 1px solid #8f8f8f;
            }
        }
        @include RWD(mobile){
            
        }
    }

    // CP - Suivi administratif
    #page-suiviadministratif{}

    // CP - Petites annonces
    #page-petitesannonces{

        .annonce-actions{
            margin-bottom: 20px;
            display: flex;
            a{
                @extend %atom-btn-int--core;
                @extend %btn-animation;
            }

            .btn-delete-ad{
                //margin-right: auto;
            }
            .btn-create-ad{
                margin-right: 20px;
                margin-left: auto;
            }
        }

        .annonce-list{
            border: 1px solid #8f8f8f;
            .title{
                height: 70px;
                width: 100%;
                padding: 19px 160px 19px 40px;
                font-size: 3.2rem;
                font-weight: 500;
                line-height: 3.2rem;
                text-align: left;
                color: $second-color;
                background-color: #fff;
                border-bottom: 1px solid #8f8f8f;
            }

            ul{
                li{
                    padding: 10px 10px 10px 60px;
                    border-bottom: 1px solid #8f8f8f;
                    overflow: hidden;
                    display:flex;
                    position: relative;
    
                    &:last-child{
                        border-bottom:0;
                    }
                    
                    .checkbox-wrapper{
                        position: absolute;
                        top: calc(50% - 8px);
                        left: 20px;
                        width: 15px;
                        height: 15px;

                        input[type="checkbox"]{
                            position: absolute;
                            z-index:2;
                            top: 0;
                            left: 0;
                            opacity: 0.01;
                            width: 100%;
                            height: 100%;
                            margin: 0;
                            padding: 0;
    
                            & + .fake-checkbox{
                                position: absolute;
                                z-index:1;
                                left: 0;
                                top: 0;
                                width: 100%;
                                height: 100%;
                                border:1px solid #191919;

                                &:after{
                                    content: "✔";
                                    color: $core-color;
                                    position: relative;
                                    z-index: 3;
                                    
                                    font-size: 0;
                                    transition: all 0.1s ease;
                                    top:7px;
                                    left: 7px;
                                }
                            
                            }
                            &:checked + .fake-checkbox:after{
                                font-size: 30px;
                                top: -14px;
                                left: -2px;
                            }
                        }
                    }
                    .annonce-infos{
                        flex-grow: 1;
                        padding: 14px 0;
                        font-size: 2rem;
                        font-weight: 500;
                    }
                    .annonce-link{
                        flex-grow: 0;
                        flex-shrink: 0;
                        @extend %atom-btn-int--core;
                        @extend %btn-animation;
                    }
                }
            }

        }

        @include RWD(tablet){
            .annonce-list ul li{
                padding: 10px 10px 10px 20px
            }
        }

    }

    // CP - Petite annonce - page annonce
    #page-petitesannonces-annonce{
        .ad-block{
            padding: 9px 0 9px 20px;
            strong{
                display:block;
                font-size: 1.6rem;
                line-height: 2.2rem;
            }
        }
    }

    // CP - Petites annonces - creation
    #page-petitesannonces-creation{
        .errors{
            color: $error;
            li{
                margin-bottom: 20px;
            }
        }
        .form-wrapper{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .form-col{
                width: calc(50% - 60px);
            }
        }

        button[type="submit"]{
            float: right;
            @extend %atom-btn-int--core;
            @extend %btn-animation;
        }

        .form-group{

            width: 100%;

            label{
                display: block;
                line-height: 2.6rem;
                margin-bottom: 6px;
                font-weight: 600;
                font-size: 1.6rem;
            }
            input[type="email"], input[type="text"], textarea{
                width: 100%;
                margin-bottom: 20px;
                border: 1px solid #ced6dc;
                font-size: 1.5rem;
                line-height: 2.6rem;
                padding: 4px 30px;
            }

            &.mandatory label:after{
                content: '*';
                color: $error;
            }

        }

    }

    // CP - Bibliothèque
    #page-bibliotheque{}


    // page FAQ
    #page-faq{
        .accordion .content .rte{
            padding: 20px;
        }
    }

    #form_adhesion{
        .result{
            margin: 0 calc((-100vw + 101%)/2);
            padding: 30px;
            color: #fff;
            background-color: $core-color;

            a{
                color: #fff;
            }
        }
    }

    #first-connect{
        p{
            margin-bottom: 40px;
        }
        .page-content{
            width: 100%;
            max-width: 920px;
            margin-left: auto;
            margin-right: auto;
            position: relative;
        }
        .form-group{
            input, label{
                display: block;
            }
            label{
                text-transform: uppercase;
            }
        }

        .form-cancel{
            clear: none;
            position: absolute;
            bottom: 0;
            margin: 0;
            right: 0;
            width: auto;

            button{
                opacity: 0.5;
            }
        }
    }

    &.class_identifier_contact_form .container > p,
    #page-mdp-oublie > p,
    #page-paiement-formation > p{
        margin-bottom: 30px;
    }

    #page-paiement-formation {
        select[name="montant"],
        select[name="number_persons"]{
            height: 35px;
            width: 100%;
        }
    }

    #page-paiement-summary{
        #PaymentRequest{
            label{
                font-size: 1.6rem;
                font-weight: 600;
                color: #333;
                letter-spacing: 0;
            }  
            label + p{
                margin-bottom: 15px;
            }
        } 
        
        button[type="button"]{
            @extend %atom-btn-int--core;
        }
    }

    #page-paiement-validation{
        label{
            font-size: 1.6rem;
            font-weight: 600;
            color: #333;
            letter-spacing: 0;
        }  
        label + p{
            margin-bottom: 15px;
        }
    }

    #first-connect,
    #page-mdp-oublie,
    #page-reset-mdp{
        .errors{
            color: $error;
            ul {
                margin-bottom: 20px;
            }
        }
    }
    
}