%molecule-media-video{
    .media-body{
        padding: 0;
    }
    .media-text{
        padding: 20px;
    }

    .media-ratio{
        position: relative;
        overflow: hidden;
        padding-bottom: 57%;

        & > *{
            position: absolute;
            top: 50%; left: 50%;
            width: 100%;
            height: 100%;
            transform: translate(-50%, -50%);
        }
    }
    .media-actions{
        padding: 20px;
        padding-top: 0;
    }
}