%icon-arrow-select{
    content: '';
    display: inline-block;
    position: absolute;
    right: 40px;
    top: 52%;
    transform: translateY(-50%);
    height: 10px;
    width: 20px;
    background-image: url(svg-bg-uri($svg-arrow-select, #494949));
    background-size: 20px 10px;
}

%icon-back-map{
    &:before{
        background-image: url( svg-bg-uri($svg-arrow1, $icon-color) );
        content: '';
        width: 50px;
        height: 25px;
        background-position: right center; 
        background-repeat: no-repeat;
        background-size: 20px;
        display: block;
        position: absolute;
        left: 0px; 
        top: 16px;
        transform: rotate(180deg);
    }  
    &:hover, &:focus{
        &:before{
            background-image: url( svg-bg-uri($svg-arrow1, $icon-color-active) );
        }
    }
}