.front{

    #header--cms{
        position: relative;
        z-index:2;
    }

    .home-carousel{
        height: 500px;
        margin-bottom: 45px;
        z-index: 1;

        @include RWD(tablet){
            height: auto;
        }
    }

    .container{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .block-adhesion{
        width: calc(50% - 25px);
        margin-bottom: 75px;
        overflow:hidden;
        
        .wrapper{
            border: 1px solid #8f8f8f;
            position:relative;
            padding-bottom:30px;
        }

        .title{
            position: absolute;
            top:0;
            left: 0;
            height: 35px;
            padding: 0 20px;
            font-size: 2.2rem;
            font-weight: 600;
            line-height: 3.5rem;
            color: #fff;
            text-transform: uppercase;
            background-color: $core-color;
            display: inline-block;
        }

        .visu{
            height: 475px;
            width: 100%;
            border-bottom: 1px solid #8f8f8f;
            margin-bottom: 30px;
            background-size: cover;
            background-position: 50%;                
        }

        h3{
            font-weight: 600;
            text-transform: uppercase;
            font-size: 2rem;
            margin-bottom: 35px;
            padding: 0 20px;
        }

        p{
            padding: 0 20px;
            font-size: 18px;
            line-height: 2.2rem;
            max-height: 88px;
            color:#5e5e5e;
        }

        .read-more{
            @extend %btn-animation;
            display:inline-block;
            float:right;
            height: 32px;
            padding: 0 20px;
            font-size: 1.6rem;
            line-height: 3.2rem;
            background-color: #f4f9f6;
            color: #201f1f;
            border: 1px solid #8f8f8f;
            border-top-width: 0;
            text-decoration: none;
        }

        @include RWD(tablet){
            width: 100%;
        }
    }

    .block-news{
        display: flex;
        flex-flow: row wrap;
        width: calc(50% - 25px);
        margin-bottom: 75px;

        .title{
            height: 35px;
            padding: 0 20px;
            margin-bottom: 20px;
            font-size: 2.2rem;
            font-weight: 600;
            line-height: 3.5rem;
            color: #fff;
            text-transform: uppercase;
            background-color: $core-color;
            display: inline-block;
        }
        
        .news-list{
            width: 100%;
            
            .news-item{
                overflow: hidden;
                display: flex;
                flex-flow: row wrap;
                margin-bottom: 18px;

                &:last-child{
                    margin-bottom: 45px;
                }

                .visu{
                    height: 140px;
                    width: 170px;
                    border: 1px solid #8f8f8f;
                    background-size: cover;
                    background-position: 50%;                
                }
                .content{
                    height: 140px;
                    width: calc(100% - 170px);
                    border-bottom: 1px solid #8f8f8f;
                    padding:0 20px;

                    h3{
                        height:65px;
                        font-size: 2rem;
                        font-weight: 600;
                        text-transform: uppercase;
                    }
                    p{
                        color:#5e5e5e;
                        font-size: 1.6rem;

                    }
                }

                .read-more{
                    @extend %btn-animation;
                    display:inline-block;
                    height: 32px;
                    margin-left: auto;
                    padding: 0 20px;
                    font-size: 1.6rem;
                    line-height: 3.2rem;
                    background-color: #f4f9f6;
                    color: #201f1f;
                    border: 1px solid #8f8f8f;
                    border-top-width: 0;
                    text-decoration: none;
                }
            }
            
        }
    
        .view-all{
            @extend %btn-animation;
            display: block;
            margin:0 auto;
            height: 60px;
            padding: 17px 60px;
            font-size: 1.6rem;
            line-height: 2.6rem;
            text-decoration: none;
            color: #fff;
            background-color: $core-color;
            border: 1px solid #8f8f8f;

            &:hover,
            &:focus,
            &:active{
                border-color: #fff;
            }
        }

        // Cas 1 seule news
        &.alone-in-the-news{
            position: relative;

            .title{
                position: absolute;
                top:0;
                left: 0;
                margin-bottom: 0;
            }

            .news-list{
                .news-item{
                    overflow: hidden;
                    display: block;
                    margin-bottom: 0;

                    &:last-child{
                        margin-bottom: 45px;
                    }

                    .visu{
                        height: auto;
                        width: 100%;
                        padding-top: 62.4%;
                        border:  1px solid #8f8f8f;
                        background-size: cover;
                        background-position: 50%;                
                    }
                    .content{
                        height: auto;
                        width: 100%;
                        border: 1px solid #8f8f8f;
                        border-top: none;
                        padding:30px 20px;

                        h3{
                            height: auto;
                            font-weight: 600;
                            margin-bottom: 35px;
                            padding: 0 20px;
                        }
                
                        p{
                            padding: 0 20px;
                            font-size: 18px;
                            line-height: 2.2rem;
                            max-height: 88px;
                            color:#5e5e5e;
                        }
                    }

                    .read-more{
                        float:right;
                    }
                }
            }
        }

        @include RWD(tablet){
            width: 100%;
        }


    }

    .block-training{
        height: 560px;
        padding: 40px 0;
        background: linear-gradient(to bottom, rgba(62,131,152,0.8) 0%, rgba(71,174,158,0.8) 100%), url(../images/training-background.jpg);
        background-size: cover;

        .container{
            flex-flow: row wrap;
        }

        .title{
            display: inline-block;
            height: 34px;
            padding: 0 20px;
            margin-bottom: 20px;
            font-size: 2.2rem;
            line-height: 3.4rem;
            font-weight: 600;
            color: #fff;
            text-transform: uppercase;
            background-color: $second-color;
            border: 1px solid #fff;
        }

        .training-list{
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            
            .training-item{
                flex:1;
                padding: 0 25px;
                text-align: center;
                border-right: 1px solid #8FC0AA;
                
                a{
                    text-decoration: none;
                    color: #fff;

                    .visu{
                        &:before, &:after{
                            opacity: 0;
                            display: block;
                            position: absolute;
                            transition: opacity 0.3s ease;
                        }
                        &:before{
                            content: '+';
                            z-index: 3;
                            top: calc(50% - 19px);
                            left: calc(50% - 19px);
                            width: 38px;
                            height: 38px;
                            font-size: 3rem;
                            text-align: center;
                            line-height: 36px;
                            border: 1px solid #fff;
                            border-radius: 50%;
                        }
                        &:after{
                            content: '';
                            z-index: 2;
                            top:0;
                            left: 0;
                            background: linear-gradient(to bottom, rgba(62,131,152,0.8) 0%, rgba(71,174,158,0.8) 100%);
                            height: 100%;
                            width: 100%;
                        }
                    }
                    &:hover, &:focus{
                        .visu{
                            &:before, &:after{
                                opacity: 1;
                            }
                        }
                    }
                }

                .visu{
                    height: 160px;
                    width: 100%;
                    background-size: cover;
                    background-position: 50%;
                    position: relative;
                }

                .training-date{
                    height: 45px;
                    line-height: 45px;
                    font-size: 1.6rem;
                }
                .training-title{
                    font-size: 2rem;
                    font-weight: 600;
                    line-height: 2.6rem;
                    height: 104px;
                    text-transform: uppercase;
                }

                &:first-child{
                    padding-left:0;
                }
                &:last-child{
                    padding-right: 0;
                    border: none;
                }
            }
        }

        &.alone-in-the-training{

            .training-list{
                margin-bottom: 70px;
                .training-item{
                    width: 100%;
                    text-align: left;

                    .visu{
                        width: calc(50% - 25px);
                        margin-right: 50px;
                        float: left;
                        height: auto;
                        padding-top: 20.8%;
                    }
                }
            }
        }

        .view-all{
            @extend %btn-animation;
            display: block;
            margin:0 auto;
            height: 60px;
            padding: 17px 60px;
            font-size: 1.6rem;
            line-height: 2.6rem;
            text-decoration: none;
            color: #fff;
            background-color: $second-color;
            border: 1px solid #fff;

            &:hover,
            &:focus,
            &:active{
                border-color: #8f8f8f;
                background-color: $core-color;
            }
        }

        @include RWD(tablet){
            width: 100%;
            height: auto;

            .training-list{
                flex-wrap: wrap;
                .training-item{
                    flex:none;
                    padding: 0 25px !important;
                    width: 50%;
                    border: none;
                }
            }
        }
        @include RWD(mobile){
            .training-list .training-item{
                width: 100%;
            }
        }
    }

}