%atom-sliders-dot--full{
    width: 10px;
    height: 10px;
    margin: 0 3px;
    background-color: transparent;
    border-radius: 50%;
    border: 1px solid $accent-color;
    span{
        display:none;
    }

    &.active{
        cursor: auto;
        background-color: $accent-color;
    }
    &:not(.active){
        &:hover, &:focus{
            background-color: rgba($accent-color, 0.2)
        }
    }
}