// Ajouter  fill="' + $svgFillPH + '" 

// DIVERS
$svg-exclamation:   '<svg xmlns="http://www.w3.org/2000/svg" fill="' + $svgFillPH + '"  width="100" height="100" viewBox="0 0 100 100"><path d="M45.8 68.1c0 2.4 2 4.4 4.4 4.4s4.4-2 4.4-4.4c0-2.5-2-4.4-4.4-4.4S45.8 65.7 45.8 68.1z"/><path d="M52.5 54.9v-23c0-1.1-0.9-2-2-2s-2 0.9-2 2v23c0 1.1 0.9 2 2 2S52.5 56 52.5 54.9z"/></svg>';
// CARTO
$svg-map-menu:      '<svg xmlns="http://www.w3.org/2000/svg" fill="' + $svgFillPH + '"  viewBox="0 0 62.1 62.1"><path class="st0" d="M57.9 0H4.1C1.8 0 0 1.8 0 4.1s1.8 4.1 4.1 4.1H58c2.3 0 4.1-1.8 4.1-4.1C62 1.8 60.2 0 57.9 0z"/><path class="st0" d="M57.9 53.9H4.1C1.8 53.9 0 55.7 0 58c0 2.3 1.8 4.1 4.1 4.1H58c2.3 0 4.1-1.8 4.1-4.1C62 55.7 60.2 53.9 57.9 53.9z"/><path class="st0" d="M57.9 26.9H4.1C1.8 26.9 0 28.7 0 31c0 2.3 1.8 4.1 4.1 4.1H58c2.3 0 4.1-1.8 4.1-4.1C62 28.7 60.2 26.9 57.9 26.9z"/></svg>';
$svg-map-search:    '<svg xmlns="http://www.w3.org/2000/svg" fill="' + $svgFillPH + '"  viewBox="0 0 483.1 483.1"><path class="st0" d="M332.7 315.4c30.9-33.4 50.1-78.2 50.1-127.5C382.9 84.4 298.7 0 195 0S7.2 84.4 7.2 187.9 91.3 375.7 195 375.7c42.2 0 81-13.9 112.5-37.4L447.2 478c3.4 3.4 7.6 5.1 11.9 5.1s8.8-1.7 11.9-5.1c6.5-6.5 6.5-17.3 0-24.1L332.7 315.4zM41.2 187.9C41.2 103.1 110 34 195 34c84.7 0 153.9 68.8 153.9 153.9S280 341.7 195 341.7 41.2 272.6 41.2 187.9z"/></svg>';
$svg-map-plus:      '<svg xmlns="http://www.w3.org/2000/svg" fill="' + $svgFillPH + '"  viewBox="0 0 62.1 62.1"><path class="st0" d="M57.9 26.9H4.1C1.8 26.9 0 28.7 0 31s1.8 4.1 4.1 4.1H58c2.3 0 4.1-1.8 4.1-4.1C62 28.7 60.2 26.9 57.9 26.9z"/><path class="st0" d="M35.1 57.9V4.1C35.1 1.8 33.3 0 31 0s-4.1 1.8-4.1 4.1V58c0 2.3 1.8 4.1 4.1 4.1C33.3 62 35.1 60.2 35.1 57.9z"/></svg>';
$svg-map-minus:     '<svg xmlns="http://www.w3.org/2000/svg" fill="' + $svgFillPH + '"  viewBox="0 0 62.1 8.2"><path class="st0" d="M57.9 0H4.1C1.8 0 0 1.8 0 4.1c0 2.3 1.8 4.1 4.1 4.1H58c2.3 0 4.1-1.8 4.1-4.1C62 1.8 60.2 0 57.9 0z"/></svg>';
$svg-map-chevron:   '<svg xmlns="http://www.w3.org/2000/svg" fill="' + $svgFillPH + '"  viewBox="0 0 16 16"><path class="st0" d="M13.1 7.4L6.5 0.7c-0.4-0.4-1-0.4-1.4 0s-0.4 1 0 1.4l5.9 6L5.1 14c-0.4 0.4-0.4 1 0 1.4s1 0.4 1.4 0l6.6-6.6c0.2-0.2 0.3-0.5 0.3-0.7C13.4 7.8 13.3 7.6 13.1 7.4z"/></svg>';
$svg-map-itinary:   '<svg xmlns="http://www.w3.org/2000/svg" fill="' + $svgFillPH + '"  viewBox="0 0 513.3 513.3"><path class="st0" d="M406.4 0l-29.9 29.9L432 85.6h-89.8c-59.9 0-106.9 47.1-106.9 106.9v106.9c-17.1-12.8-40.6-21.4-64.2-21.4H81.3l55.6-55.6L107 192.5 0 299.4l106.9 106.9 29.9-29.9 -55.6-55.6H171c36.4 0 64.2 27.8 64.2 64.2v128.3H278V192.5c0-36.4 27.8-64.2 64.2-64.2H432l-55.6 55.6 29.9 29.9 106.9-106.9L406.4 0z"/></svg>'; 
$svg-map-flag:      '<svg xmlns="http://www.w3.org/2000/svg" fill="' + $svgFillPH + '" viewBox="0 0 512 512"><path class="st0" d="M192 416h-32V0h32V416zM384 96L512 0H224v192h288L384 96zM224 323.4v32.5c55.8 8.8 96 32.3 96 60.1 0 35.3-64.5 64-144 64S32 451.3 32 416c0-27.8 40.2-51.3 96-60.1v-32.5C58.9 333.2 0 364.6 0 416c0 63 88.6 96 176 96s176-33 176-96C352 364.6 293.1 333.2 224 323.4z"/></svg>'; 
$svg-map-reset:     '<svg xmlns="http://www.w3.org/2000/svg" fill="' + $svgFillPH + '"  viewBox="0 0 34.1 34.1"><path d="M17 32.6c-6.6 0-12.5-4.2-14.7-10.5 -0.2-0.5 0.1-1 0.6-1.1 0.5-0.2 1 0.1 1.1 0.6 1.9 5.6 7.1 9.3 13 9.3 7.6 0 13.8-6.2 13.8-13.8 0-0.5 0.4-0.9 0.9-0.9 0.5 0 0.9 0.4 0.9 0.9C32.6 25.6 25.6 32.6 17 32.6z"/><path d="M2.4 17.9c-0.5 0-0.9-0.4-0.9-0.9 0-8.6 7-15.5 15.5-15.5 6.6 0 12.5 4.2 14.7 10.5 0.2 0.5-0.1 1-0.6 1.1 -0.5 0.2-1-0.1-1.1-0.6C28.1 7 22.9 3.3 17 3.3 9.4 3.3 3.3 9.4 3.3 17 3.3 17.5 2.9 17.9 2.4 17.9z"/><path d="M30.8 13.3c-0.1 0-0.2 0-0.3 0l-6.2-1.9c-0.5-0.1-0.7-0.6-0.6-1.1s0.6-0.7 1.1-0.6l5.4 1.7 2-5.1c0.2-0.5 0.7-0.7 1.2-0.5C34 6 34.2 6.5 34 6.9l-2.3 5.8C31.5 13.1 31.2 13.3 30.8 13.3z"/><path d="M0.9 28.3c-0.1 0-0.2 0-0.3-0.1C0.1 28-0.1 27.5 0.1 27l2.3-5.8c0.2-0.4 0.6-0.7 1.1-0.5l6.2 1.9c0.5 0.1 0.7 0.6 0.6 1.1 -0.1 0.5-0.6 0.7-1.1 0.6l-5.4-1.7 -2 5.1C1.6 28.1 1.2 28.3 0.9 28.3z"/></svg>'; 
// Citation
$svg-quote1:        '<svg xmlns="http://www.w3.org/2000/svg" fill="' + $svgFillPH + '" viewBox="0 0 85.08 55.38"><title>Plan de travail 1</title><path d="M7.47,54.85H6.8a2.57,2.57,0,0,1-2.59-2.25c-.28-1.57-.58-3.39-.79-5.15a2.65,2.65,0,0,1,1.84-3.05l.14,0A18.57,18.57,0,0,0,11,41.74a11.33,11.33,0,0,0,4.09-5.15A15.86,15.86,0,0,1,10.47,35,17.49,17.49,0,0,1,.63,21.78a18,18,0,0,1,14-20.68c7.84-1.68,14.64.67,19.66,6.79a20.7,20.7,0,0,1,4.1,8.81,24.34,24.34,0,0,1,.65,6v0A30.48,30.48,0,0,1,27,46.92,38.19,38.19,0,0,1,11.23,54.2l-1.78.31-1.82.31Zm0-7c.13,1,.28,1.93.44,2.85l.81-.14,1.71-.29a34.13,34.13,0,0,0,14.08-6.49A26.31,26.31,0,0,0,35,22.63a20.64,20.64,0,0,0-.53-5,17,17,0,0,0-3.31-7.24c-4.08-5-9.37-6.78-15.74-5.42A14,14,0,0,0,4.58,21.12a13.52,13.52,0,0,0,7.74,10.35,12.5,12.5,0,0,0,5.29,1.38l.78,0,.3.3a2.07,2.07,0,0,1,.81.83,2.17,2.17,0,0,1,.14,1.7A16,16,0,0,1,13.34,45,20.87,20.87,0,0,1,7.51,47.86Z"/><path d="M52.37,54.87a2.45,2.45,0,0,1-2.53-2.23c-.16-.91-.29-1.81-.42-2.71s-.24-1.67-.38-2.49a2.59,2.59,0,0,1,.33-2,2.75,2.75,0,0,1,1.71-1.18A20.59,20.59,0,0,0,56,42.17a11.75,11.75,0,0,0,4.83-5.5,13.6,13.6,0,0,1-2.79-.77A17.69,17.69,0,0,1,46.34,22,17.37,17.37,0,0,1,49.93,7.51,17.77,17.77,0,0,1,61.06.88a19.46,19.46,0,0,1,15.88,4,20.12,20.12,0,0,1,6,8.31,24.41,24.41,0,0,1,1.75,9.26,29.79,29.79,0,0,1-2.79,12.86A32.07,32.07,0,0,1,68.73,49.76a36.68,36.68,0,0,1-13.63,4.76c-.73.1-1.44.21-2.25.31A2.88,2.88,0,0,1,52.37,54.87Zm-.1-4h0Zm.89-3c.08.5.15,1,.22,1.52s.13.89.2,1.34l.94-.13a32.75,32.75,0,0,0,12.15-4.24A28.23,28.23,0,0,0,78.29,33.62,25.5,25.5,0,0,0,80.7,22.45a20.42,20.42,0,0,0-1.45-7.75A16.22,16.22,0,0,0,74.46,8,15.6,15.6,0,0,0,61.74,4.82,13.65,13.65,0,0,0,53.08,10a13.34,13.34,0,0,0-2.81,11.3,13.75,13.75,0,0,0,9.24,10.9,13.37,13.37,0,0,0,4.12.77L66,33.1l-.55,2.31a15.8,15.8,0,0,1-7.29,10.14A22.24,22.24,0,0,1,53.16,47.85Z"/></svg>';
// Fleche gallery de liens
$svg-arrow1:        '<svg xmlns="http://www.w3.org/2000/svg" fill="' + $svgFillPH + '" viewBox="0 0 31.49 22.14"><path d="M21.2.33A1.12,1.12,0,1,0,19.62,1.9l8,8H1.11A1.11,1.11,0,0,0,0,11.06a1.12,1.12,0,0,0,1.11,1.13H27.67l-8,8a1.14,1.14,0,0,0,0,1.59,1.11,1.11,0,0,0,1.59,0l10-10a1.09,1.09,0,0,0,0-1.57Z"/></svg>';
// Closes
$svg-close:         '<svg xmlns="http://www.w3.org/2000/svg" fill="' + $svgFillPH + '" viewBox="0 0 380.2 380.2"><path d="M324.5 55.7C288.6 19.8 240.9 0 190.1 0 139.3 0 91.6 19.8 55.7 55.7 19.8 91.6 0 139.3 0 190.1c0 50.8 19.8 98.5 55.7 134.4 35.9 35.9 83.6 55.7 134.4 55.7 50.8 0 98.5-19.8 134.4-55.7 35.9-35.9 55.7-83.6 55.7-134.4 0-50.8-19.8-98.5-55.7-134.4zm-15.3 253.5c-31.8 31.8-74.2 49.4-119.1 49.4-45 0-87.3-17.5-119.1-49.4C5.3 243.5 5.3 136.6 71 71c31.8-31.8 74.2-49.4 119.1-49.4 45 0 87.3 17.5 119.1 49.4 65.7 65.6 65.7 172.5 0 238.2zm0 0"/><path d="M273.8 106.3c-4.2-4.2-11-4.2-15.3 0L190 174.8l-68.5-68.5c-4.2-4.2-11-4.2-15.3 0-4.2 4.2-4.2 11 0 15.3l68.5 68.5-68.5 68.5c-4.2 4.2-4.2 11 0 15.3 2.1 2.1 4.9 3.2 7.6 3.2 2.7 0 5.5-1 7.6-3.2l68.5-68.5 68.5 68.5c2.1 2.1 4.9 3.2 7.6 3.2 2.8 0 5.5-1 7.6-3.2 4.2-4.2 4.2-11 0-15.3l-68.3-68.5 68.5-68.5c4.3-4.2 4.3-11 0-15.3zm0 0"/></svg>'; 


/*********************************
 *           SPE 
 *********************************/
$svg-arrow-left:    '<svg xmlns="http://www.w3.org/2000/svg" fill="' + $svgFillPH + '" width="256.7" height="477.2"><path d="M37.2 238.6L252.7 23.1c5.3-5.3 5.3-13.8 0-19.1s-13.8-5.3-19.1 0L8.5 229.1c-5.3 5.3-5.3 13.8 0 19.1l225.1 225c2.6 2.6 6.1 4 9.5 4s6.9-1.3 9.5-4c5.3-5.3 5.3-13.8 0-19.1L37.2 238.6z"/></svg>';
$svg-arrow-right:   '<svg xmlns="http://www.w3.org/2000/svg" fill="' + $svgFillPH + '" width="252.1" height="477.2"><path d="M248.2 229.1L23.1 4C17.8-1.3 9.3-1.3 4 4s-5.3 13.8 0 19.1l215.5 215.5L4 454.1c-5.3 5.3-5.3 13.8 0 19.1 2.6 2.6 6.1 4 9.5 4 3.4 0 6.9-1.3 9.5-4l225.1-225.1c5.3-5.2 5.3-13.8.1-19z"/></svg>';
$svg-deconnexion:   '<svg xmlns="http://www.w3.org/2000/svg" fill="' + $svgFillPH + '" width="42.9" height="42"><path class="st3" d="M21.2 39.3H10.4c-2.9 0-5.3-2.4-5.3-5.3V8c0-2.9 2.4-5.3 5.3-5.3h10.9c.7 0 1.2-.5 1.2-1.2S22 .4 21.3.4H10.4C6.2.4 2.8 3.8 2.8 8v26c0 4.2 3.4 7.7 7.7 7.7h10.8c.7 0 1.2-.5 1.2-1.2s-.7-1.2-1.3-1.2z"/><path class="st3" d="M40.6 20.2l-7.5-7.5c-.5-.5-1.2-.5-1.7 0s-.5 1.2 0 1.7l5.5 5.5H13c-.7 0-1.2.5-1.2 1.2s.5 1.2 1.2 1.2h24l-5.5 5.5c-.5.5-.5 1.2 0 1.7.2.2.5.4.8.4.3 0 .6-.1.8-.4l7.5-7.5c.5-.6.5-1.4 0-1.8z"/></svg>';
$svg-facebook:      '<svg xmlns="http://www.w3.org/2000/svg" fill="' + $svgFillPH + '" width="156.6" height="310"><path d="M5 165.1h34V305c0 2.8 2.2 5 5 5h57.6c2.8 0 5-2.2 5-5V165.8h39.1c2.5 0 4.7-1.9 5-4.4l5.9-51.5c.2-1.4-.3-2.8-1.2-3.9-.9-1.1-2.3-1.7-3.7-1.7h-45V72c0-9.7 5.2-14.7 15.6-14.7h29.4c2.8 0 5-2.2 5-5V5c0-2.8-2.2-5-5-5h-42.4c-7 0-31.5 1.4-50.8 19.2C37 38.8 40 62.4 40.7 66.5v37.8H5c-2.8 0-5 2.2-5 5v50.8c0 2.8 2.2 5 5 5z"/></svg>';
$svg-marker:        '<svg xmlns="http://www.w3.org/2000/svg" fill="' + $svgFillPH + '" width="370.9" height="512"><path d="M185.4 0C83.2 0 0 83.2 0 185.4c0 126.9 165.9 313.2 173 321 6.6 7.4 18.2 7.4 24.8 0 7.1-7.9 173-194.1 173-321C370.9 83.2 287.7 0 185.4 0zm0 278.7c-51.4 0-93.3-41.9-93.3-93.3s41.9-93.3 93.3-93.3 93.3 41.9 93.3 93.3-41.8 93.3-93.3 93.3z"/></svg>';
$svg-pdf:           '<svg xmlns="http://www.w3.org/2000/svg" fill="' + $svgFillPH + '" width="466.7" height="533.3"><path d="M405.2 307c-7.1-7-22.9-10.7-46.9-11-16.2-.2-35.8 1.3-56.3 4.1-9.2-5.3-18.7-11.1-26.1-18.1-20-18.7-36.8-44.7-47.2-73.2.7-2.7 1.3-5 1.8-7.4 0 0 11.3-64.1 8.3-85.8-.4-3-.7-3.8-1.5-6.1l-1-2.5c-3.1-7.1-9.1-14.6-18.5-14.2l-5.5-.2h-.2c-10.5 0-19.1 5.4-21.3 13.4-6.8 25.2.2 63 13 111.8l-3.3 8c-9.2 22.3-20.6 44.8-30.8 64.7l-1.3 2.6c-10.7 20.9-20.3 38.6-29.1 53.6l-9.1 4.8c-.7.3-16.2 8.6-19.8 10.8-30.9 18.4-51.3 39.3-54.7 55.9-1.1 5.3-.3 12.1 5.2 15.2l8.8 4.4c3.8 1.9 7.8 2.9 11.9 2.9 22 0 47.5-27.4 82.7-88.7 40.6-13.2 86.8-24.2 127.3-30.3 30.9 17.4 68.8 29.4 92.8 29.4 4.3 0 7.9-.4 10.9-1.2 4.6-1.2 8.5-3.8 10.8-7.4 4.6-7 5.6-16.6 4.3-26.5-.3-2.9-2.7-6.5-5.2-9zM76.9 424c4-11 19.9-32.6 43.3-51.9 1.5-1.2 5.1-4.6 8.4-7.8-24.5 39.2-40.9 54.8-51.7 59.7zm139-320c7.1 0 11.1 17.8 11.4 34.5.3 16.7-3.6 28.4-8.4 37.1-4-12.8-6-33.1-6-46.3 0 0-.3-25.3 3-25.3zm-41.5 228c4.9-8.8 10-18.1 15.3-28 12.8-24.1 20.8-43 26.8-58.5 11.9 21.7 26.8 40.2 44.3 55 2.2 1.8 4.5 3.7 6.9 5.5-35.6 7.1-66.3 15.6-93.3 26zm224-2c-2.2 1.4-8.4 2.1-12.3 2.1-12.9 0-28.8-5.9-51.1-15.5 8.6-.6 16.4-1 23.5-1 12.9 0 16.7-.1 29.3 3.2s12.8 9.9 10.6 11.2zm38.8-226.1l-74.4-74.4C346.6 13.3 314.6 0 291.7 0h-250C18.8 0 0 18.7 0 41.7v450c0 22.9 18.8 41.7 41.7 41.7H425c22.9 0 41.7-18.8 41.7-41.7V175c0-22.9-13.3-54.9-29.5-71.1zm-23.6 23.5c1.6 1.6 3.3 3.6 4.8 5.9h-85.1V48.2c2.3 1.6 4.3 3.2 5.9 4.8l74.4 74.4zm19.7 364.3c0 4.5-3.8 8.3-8.3 8.3H41.7c-4.5 0-8.3-3.8-8.3-8.3v-450c0-4.5 3.8-8.3 8.3-8.3h250c2.5 0 5.3.3 8.3.9v132.4h132.4c.6 3 .9 5.8.9 8.3v316.7z"/></svg>';
$svg-plus:          '<svg xmlns="http://www.w3.org/2000/svg" fill="' + $svgFillPH + '" width="612" height="612"><path d="M612 286.9H325.1V.3h-38.2v286.6H0v38.2h286.9v286.6h38.2V325.1H612"/></svg>';
$svg-print:         '<svg xmlns="http://www.w3.org/2000/svg" fill="' + $svgFillPH + '" width="474" height="495"><path d="M474 346.6V167.7c0-23.8-19.4-43.2-43.2-43.2H384V33.4c0-15.8-12.9-28.7-28.7-28.7H118.5c-15.8 0-28.7 12.9-28.7 28.7v91.2H43.2C19.4 124.5 0 143.9 0 167.7v178.9c0 23.8 19.4 43.2 43.2 43.2h46.7V466c0 15.8 12.9 28.7 28.7 28.7h236.8c15.8 0 28.7-12.9 28.7-28.7v-76.2h46.7c23.8 0 43.2-19.3 43.2-43.2zM114.4 33.4c0-2.3 1.9-4.2 4.2-4.2h236.8c2.3 0 4.2 1.9 4.2 4.2v91.2H114.4V33.4zM359.6 466c0 2.3-1.9 4.2-4.2 4.2H118.6c-2.3 0-4.2-1.9-4.2-4.2V269.4h245.1V466h.1zm12.2-221.1H102.2c-6.8 0-12.3 5.5-12.3 12.3v108.1H43.2c-10.3 0-18.7-8.4-18.7-18.7V167.7c0-10.3 8.4-18.7 18.7-18.7h387.5c10.3 0 18.7 8.4 18.7 18.7v178.9c0 10.3-8.4 18.7-18.7 18.7H384V257.2c.1-6.8-5.4-12.3-12.2-12.3z"/><path d="M63.5 188.1c-2.3 2.3-3.6 5.4-3.6 8.7 0 3.2 1.3 6.4 3.6 8.7 2.3 2.3 5.4 3.6 8.7 3.6 3.2 0 6.4-1.3 8.7-3.6 2.3-2.3 3.6-5.5 3.6-8.7s-1.3-6.4-3.6-8.7c-4.7-4.6-12.9-4.6-17.4 0zM306.5 357.6h-139c-6.8 0-12.3 5.5-12.3 12.3s5.5 12.3 12.3 12.3h138.9c6.8 0 12.3-5.5 12.3-12.3 0-6.8-5.5-12.3-12.2-12.3zM306.5 413.1h-139c-6.8 0-12.3 5.5-12.3 12.3s5.5 12.3 12.3 12.3h138.9c6.8 0 12.3-5.5 12.3-12.3s-5.5-12.3-12.2-12.3zM306.5 302h-139c-6.8 0-12.3 5.5-12.3 12.3s5.5 12.3 12.3 12.3h138.9c6.8 0 12.3-5.5 12.3-12.3s-5.5-12.3-12.2-12.3z"/></svg>';
$svg-profil:        '<svg xmlns="http://www.w3.org/2000/svg" fill="' + $svgFillPH + '" width="42.9" height="42"><path d="M21.4 28c1.1 0 2.1-.2 3.1-.4 1 2 4 5 12.7 7.2 2.6.7 2.7 3.9 2.6 5.8-.1.8.6 1.5 1.4 1.6h.1c.8 0 1.4-.6 1.5-1.4.3-4.8-1.4-8-4.9-8.9-8-2-10.2-4.5-10.8-5.6.6-.4 1.1-.9 1.6-1.4 4-4.5 3.3-12.2 3.3-13C31.6 1.9 23.7 1 21.4 1h-.1-.2c-4.9.1-10.3 3-10.7 10.9-.1.8-.7 8.5 3.3 13 .5.6 1.2 1.1 1.8 1.6-.7 1.1-3.1 3.5-10.7 5.4-3.3.8-5.1 4.1-4.8 8.9.1.8.7 1.4 1.5 1.4h.1c.8-.1 1.5-.8 1.4-1.6-.1-1.9 0-5.1 2.6-5.8 8.5-2.1 11.6-5.1 12.7-7.1.9.2 1.8.4 2.8.4h.3V28zm-.2-3c-2.2 0-3.9-.7-5.2-2.2-3.2-3.6-2.5-10.6-2.5-10.7V12c.3-7 5-8 7.7-8.1h.1c2.8 0 7.4 1.1 7.7 8.1v.1c0 .1.7 7.1-2.5 10.7-1.3 1.5-3 2.2-5.3 2.2z" class="st1"/></svg>';
$svg-share:         '<svg xmlns="http://www.w3.org/2000/svg" fill="' + $svgFillPH + '" width="493.5" height="394.8"><path d="M430.6 30.2H218.4c21.6 12.7 40.3 29.7 54.9 49.9h157.3c7.2 0 13.1 5.9 13.1 13.1v238.7c0 7.2-5.9 13.1-13.1 13.1H191.9c-7.2 0-13.1-5.9-13.1-13.1v-159c-6-3.1-12.3-5.7-18.8-7.7-3.8 11.8-12.1 21.8-23.5 27.8-2.4 1.3-5 2-7.6 2.8v136.1c0 34.7 28.3 63 63 63h238.7c34.7 0 63-28.3 63-63V93.2c-.1-34.8-28.3-63-63-63z"/><path d="M129 126.6c51.4 1.2 96.4 28.3 122.3 68.9 2.4 3.7 6.4 5.8 10.7 5.8 1.2 0 2.3-.2 3.5-.5 5.4-1.5 9.1-6.4 9.2-12v-1.5c0-81.1-64.9-147-145.5-149V14.8c0-5.5-3-10.5-7.9-13.1-2.3-1.1-4.7-1.7-7-1.7-2.9 0-5.8.9-8.3 2.6L5.8 71.2C2.2 73.6 0 77.7 0 82c0 4.3 2.1 8.4 5.7 10.9l100.2 69.5c2.5 1.8 5.5 2.6 8.4 2.6 2.4 0 4.7-.6 6.9-1.7 4.9-2.5 7.9-7.6 7.9-13.1v-23.6h-.1z"/></svg>';
$svg-twitter:       '<svg xmlns="http://www.w3.org/2000/svg" fill="' + $svgFillPH + '" width="310" height="262.6"><path d="M303 38.1c-4.9 2.2-9.9 4-15 5.5 6.1-6.8 10.7-14.9 13.5-23.7.6-2 0-4.1-1.6-5.4-1.6-1.3-3.9-1.4-5.7-.4-10.9 6.4-22.6 11.1-34.9 13.8-12.4-12.1-29.2-19-46.6-19-36.7 0-66.5 29.9-66.5 66.5 0 2.9.2 5.8.5 8.6-45.5-4-87.9-26.4-116.9-62-1-1.3-2.6-2-4.3-1.8-1.6.1-3.1 1-3.9 2.5-5.9 10.1-9 21.7-9 33.5 0 16 5.7 31.2 15.8 43.1-3.1-1.1-6.1-2.4-8.9-4-1.5-.9-3.4-.8-4.9 0-1.5.9-2.5 2.5-2.5 4.2v.9c0 23.9 12.9 45.5 32.6 57.2-1.7-.2-3.4-.4-5.1-.7-1.7-.3-3.5.3-4.7 1.6-1.2 1.3-1.6 3.2-1 4.8 7.3 22.8 26.1 39.5 48.7 44.6-18.8 11.8-40.3 18-62.9 18-4.7 0-9.5-.3-14.1-.8-2.3-.3-4.5 1.1-5.3 3.3-.8 2.2 0 4.6 2 5.9 29 18.6 62.6 28.4 97 28.4 67.8 0 110.1-31.9 133.8-58.8 29.5-33.4 46.4-77.7 46.4-121.4 0-1.8 0-3.7-.1-5.5 11.6-8.8 21.6-19.4 29.8-31.5 1.2-1.8 1.1-4.3-.3-6-1.5-1.7-3.9-2.3-5.9-1.4z"/></svg>';
$svg-arrow-select:  '<svg xmlns="http://www.w3.org/2000/svg" fill="' + $svgFillPH + '" width="35.2" height="15.6"><path fill="none" d="M-1-1h582v402H-1z"/><g><path d="M0 0h35.2L17.6 15.6"/></g></svg>';

$svg-dmnt-cross:    '<svg xmlns="http://www.w3.org/2000/svg" fill="' + $svgFillPH + '" width="512" height="512" viewBox="0 0 612 612"><polygon points="612 36 576.5 0.6 306 270.6 35.5 0.6 0 36 270.5 306 0 576 35.5 611.4 306 341.4 576.5 611.4 612 576 341.5 306 "/></svg>';
$svg-search:        '<svg xmlns="http://www.w3.org/2000/svg" fill="' + $svgFillPH + '" width="43.9" height="45.8"><path d="M43.3 41.8L32.5 30.5c2.8-3.3 4.3-7.5 4.3-11.8C36.8 8.6 28.5.3 18.4.3 8.3.3 0 8.5 0 18.7c0 10.1 8.3 18.4 18.4 18.4 3.8 0 7.4-1.1 10.5-3.3l10.9 11.4c.5.5 1.1.7 1.7.7.6 0 1.2-.2 1.7-.7 1-.9 1-2.5.1-3.4zM18.4 5.1c7.5 0 13.6 6.1 13.6 13.6s-6.1 13.6-13.6 13.6S4.8 26.2 4.8 18.7 10.9 5.1 18.4 5.1zm0 0"/></svg>';
$svg-home:          '<svg xmlns="http://www.w3.org/2000/svg" fill="' + $svgFillPH + '" viewBox="0 0 448.5 367.5"><path d="M444.3 174.7L242.7 11.9l-11.2-9.3c-4.2-3.5-10.3-3.5-14.6 0l-58.2 48.3V30.5c0-6.3-5.1-11.4-11.4-11.4H104c-6.3 0-11.4 5.1-11.4 11.4v75.2L4.2 177.8c-4.9 4-5.6 11.2-1.6 16 2.3 2.8 5.5 4.2 8.8 4.2 2.5 0 5.1-0.8 7.2-2.6l34.5-28.1v188.7c0 6.3 5.1 11.4 11.4 11.4h121.8c6.3 0 11.4-5.1 11.4-11.4v-88.4h53.2v88.4c0 6.3 5.1 11.4 11.4 11.4H384c6.3 0 11.4-5.1 11.4-11.4V164.6l34.5 27.9c2.1 1.7 4.6 2.5 7.2 2.5 3.3 0 6.6-1.4 8.9-4.2C449.9 185.9 449.2 178.7 444.3 174.7zM115.4 41.9H136v27.2l-20.7 16.7V41.9zM372.6 149.4v195.2h-99v-88.4c0-6.3-5.1-11.4-11.4-11.4h-76c-6.3 0-11.4 5.1-11.4 11.4v88.4h-99V149.4l44.3-36.8c0 0 103.7-85 104.4-86.1L372.6 149.4z"/></svg>';