%molecule-accordion{

    & > li{
        border: 1px solid #8f8f8f;
        border-bottom-width: 0;
        overflow:hidden;

        &:last-child{
            border-width: 1px;
        }

        .trigger{
            position: relative;
            height: 70px;
            width: 100%;
            padding: 19px 160px 19px 40px;
            font-size: 3.2rem;
            font-weight: 500;
            line-height: 3.2rem;
            text-align: left;
            color: $second-color;
            background-color: #fff;
            cursor: pointer;

            &:after{
                content: '';
                position: absolute;
                top: 18px;
                right: 110px;
                display: block;
                width: 32px;
                height: 32px;
                border: 1px solid #8f8f8f;
                background-image: url(svg-bg-uri($svg-arrow-right, #000));
                transition: all 0.25s ease;
                background-position: 50%;
                background-repeat: no-repeat;
                background-size: 10px;
            }
        }

        .content{
            transition: all 0.4s ease;

            ul.files li{
                padding: 10px 70px;
                border-bottom: 1px solid #8f8f8f;
                overflow: hidden;
                display:flex;

                &:last-child{
                    border-bottom:0;
                }
                
                .file-infos{
                    flex-grow: 1;
                    padding: 14px 0;
                    font-size: 2rem;
                    font-weight: 500;
                }
                .file-download-link{
                    flex-grow: 0;
                    flex-shrink: 0;
                    @extend %atom-btn-int--core;
                    @extend %btn-animation;
                }
            }
                  
        }
        &:not(.active){
            .content{
                height: 0 !important;
            
            }
        }
        &.active{
            .trigger{
                background-color: #f4f9f6;
                &:after{
                    transform: rotate(90deg);
                    background-color: $second-color;
                    background-image: url(svg-bg-uri($svg-arrow-right, #fff));
                }
            }
            .content{
                height: auto;
                border-top: 1px solid #8f8f8f;
                p{
                    padding: 15px;
                }
            }
        }

    }

    @include RWD(tablet){
        & > li{
            border: 1px solid #8f8f8f;
            border-bottom-width: 0;
            overflow:hidden;
    
            .trigger{
                min-height: 70px;
                height: auto;
                padding: 19px 70px 19px 20px;

                &:after{
                    right: 30px;
                }
            }
            .content ul.files li{
                padding: 10px;
            }
        }
        
    }

    @include RWD(mobile){
     
    }

}