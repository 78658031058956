%organism-header--cms{

    &.fixed{
        .region-main-nav{
            background-color: #FFF;
        }
    }

    // Region nav-top
    .region-nav-top{
        .alert-banner{
            @extend %molecule-user-alert--cms;
        }
    }

    // Region main-nav
    .region-main-nav{
        transition: all, 0.5s;
        background-color: #FFF;
        margin-bottom: 20px;
        background-repeat: no-repeat;
        background-size: cover;

        .container{
            @extend %container;
            display: flex;
            align-items: center;
            flex-flow: row wrap;
            padding-top: 40px;
            position: relative;
            z-index: 2;

            @include RWD(tablet){
                display: flex;
                padding: 20px 20px 0;
            }

            @include RWD(mobile){
                justify-content: center;
            }
        }

        #main-logo{
            height: 215px;
            width: calc(100% - 520px);
            display:flex;
            align-items: flex-end;
            margin-bottom: 35px;
            
            h1, a{
                margin-right: 70px;
            }
            a{
                display: block;
            }
            p{
                font-size: 1.6rem;
                font-family: 'Montserrat';
                color: #143785;
            }

            @include RWD(tablet){
                width: 75%;
                height: auto;
                margin-bottom: 20px;
                //min-width: 400px;
                margin-right: auto;
                h1{
                    height:135px;
                    margin-right: 50px;
                    img{
                        max-height: 100%;
                    }
                }
            }
            @include RWD(mobile){
                width: 100%;
                text-align:center;
                justify-content: center;
                h1, a{
                    margin: 0; 
                    width: 100%;
                    text-align: center;
                }
                p{
                    display: none;
                }
            }

        }    


        #login-access{
            display: block;
            height: 215px;
            width: 520px;
            padding: 0 20px 0 40px;
            margin-bottom: 35px;
            color: #ffffff;
            background-color: $accent-color;

            .btn-toggle-login{
                display: none;
    
                @extend %btn-animation;
                height: 60px;
                width: 64px;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-color: $core-color;
                background-image: url(svg-bg-uri($svg-profil, #fff));
                background-size: 38px 40px;
                &:hover, &:focus, &.active{
                    background-color: $accent-color;
                };
    
            }
            
            h2{
                font-size: 2.2rem;
                line-height: 2.4rem;
                font-weight: 600;
                text-align: center;
                text-transform: uppercase;
                padding: 20px 0 30px;
            }

            form{
                display: flex;
                flex-flow: row nowrap;

                &>div{
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: space-between;
                    .lost-pwd{
                        color: #fff;
                        font-size: 1.2rem;
                        margin: 5px 0 0 auto;
                    }
                }

                input{
                    width: calc(50% - 10px);
                    height: 35px;
                    line-height: 40px;
                    font-size: 1.8rem;
                    background: transparent;
                    border:1px solid #fff;
                    border-width: 0 0 1px;
                    color: #fff;
                    &:-webkit-autofill,
                    &:-webkit-autofill:hover, 
                    &:-webkit-autofill:focus{
                        -webkit-box-shadow: 0 0 0 30px $accent-color inset;
                        -webkit-text-fill-color: #fff;
                    }
                    &::placeholder {
                        color: #fff;
                        opacity: 1 !important;
                    }
                }

                button[type="submit"]{
                    height: 35px;
                    padding: 0 20px;
                    margin-left: 20px;
                    border:1px solid #fff;
                    color: #fff;
                    font-size: 1.6rem;

                    &:hover, &:focus{
                        background-color: #fff;
                        color: #636161;
                    };
                }
            }

            .login__infos {
                width: 50%;
                position: relative;
                color: #fff;
                font-size: 1.2rem;
                margin-top: 5px;
                cursor: pointer;

                .icon-info {
                    background-color: #fff;
                    color: $accent-color;
                    border-radius: 100%;
                    padding: 1px 5px;
                    font-weight: 500;
                }

                .info-description {
                    display: none;
                    background-color: #F8F9F0;
                    color: black;
                    padding: 10px;
                    padding-left: 30px;
                    position: absolute;
                    top: 20px;
                    border-radius: 5px;
                    box-shadow: 3px 7px 30px -16px rgba(0,0,0,0.75);
                    cursor: auto;

                    &:before {
                        content: "?";
                        position: absolute;
                        left: 10px;
                        background-color: $accent-color;
                        color: #fff;
                        border-radius: 100%;
                        padding: 1px 5px;
                        font-weight: 500;
                    }
                }
            }


            @include RWD(tablet){
                position: absolute;
                top: 30px;
                right: 20px;
                background-color: transparent;
                height: 60px;
                width: auto;
                padding: 0;

                .btn-toggle-login{
                    display: inline-block;
                    margin: 0 5px;
                }
                h2{
                    display: none;
                    position: absolute;
                    top: 70px;
                    z-index: 2;
                    width: 300px;
                    font-size: 18px;
                    height: 25px;
                    overflow: hidden;
                    padding: 0 0 0 10px;
                    right: 5px;
                    text-align: left;
                }
                form{
                    display:none;
                    background-color: $accent-color;
                    padding: 30px 10px 10px;
                    position: absolute;
                    z-index: 1;
                    right: 5px;
                    width: 300px;
                    top: 60px;
                    flex-wrap: wrap;

                    input{
                        width: 100%;
                    }

                    button[type="submit"]{
                        width: 100%;
                        margin: 20px 0 0;
                    }
                }

            }
            @include RWD(mobile){
                position: static;
                margin-bottom: 0;

                h2, form{
                    display: block;
                    top: 235px;
                    left: 0;
                    width: 100%;
                    padding: 40px 10% 20px;
                }
                h2{
                    right: auto;
                    padding: 10px 10% 20px;
                }

            }


        }

        #logged-access{
            position:absolute;
            top: 40px;
            right: 0;

            .welcome-user{
                position: absolute;
                right: 0;
                font-size: 1.6rem;
                padding-top: 10px;
                width: 300px;
                text-align: right;

                span{
                    display: block;
                    font-size: 2rem;
                    font-weight: 600;
                    text-transform: uppercase;
                }
            }

            @include RWD(tablet){
                .welcome-user{
                    display: none;
                }
                
                top: 30px;
                right: 20px;
                display: flex;
            }

            @include RWD(mobile){
                position: relative;
                top: 0;
                right: 0;
            }

            a{
                @extend %btn-animation;
                display: inline-block;
                height: 60px;
                width: 64px;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                margin-left: 10px;
                
                @include RWD(tablet){
                    display: block;
                    margin: 0 5px;
                }

                &.btn-profil{
                    background-color: $core-color;
                    background-image: url(svg-bg-uri($svg-profil, #fff));
                    background-size: 38px 40px;
                    &:hover, &:focus{
                        background-color: $accent-color;
                    };
                }

                &.btn-deconnexion{
                    background-color: $accent-color;
                    background-image: url(svg-bg-uri($svg-deconnexion, #fff));
                    background-size: 38px 40px;
                    &:hover, &:focus{
                        background-color: $core-color;
                    };
                }
            }
        }

        .rwd-btn-wrapper{
            display: none;
            position: absolute;
            top: 100px;
            right: 20px;

            @include RWD(tablet){
                display: flex;
            }

            @include RWD(mobile){
                position: static;
                top: 0;
                right: 0;
            }

            button{
                flex-shrink: 0;
            }
        }

        .toggle-menu-rwd{
            display: block;
            width: 64px;
            height: 60px;
            padding: 10px;
            color: #FFF;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            margin-left: 5px;
            margin-right: 5px;
            font-size: 4rem;
            background-color: $accent-color;
            @extend %btn-animation;
            
            &:hover, &:focus{
                background-color: $core-color;
            };

            @include RWD(mobile){
                position: absolute;
                top: 10px;
                right: 10px;
                width: 40px;
                height: 40px;
                padding: 7px;
                font-size: 24px;
            }

        }    

        #main-menu{
            width: calc(100% - 48px);
            & > ul{
                @extend %molecule-main-menu--cms;
            }
            .sub-toggler{
                display: none;
            }
        }

        .filler{
            flex: 1;

            @include RWD(tablet){
                display: none;
            }
        }

        .toggle-collapsing-bar{
            height: 48px;
            width: 48px;
            padding: 10px;
            background: url(svg-bg-uri($svg-search, #201f1f)) no-repeat;
            background-position: 50%;
            background-size: 25px 25px;
            border-bottom:1px solid #A6A6A6;
            color: #FFF;
            font-weight: bold;
            font-family: arial;
            @extend %btn-animation;
            
            &:hover, &:focus, &:active, &.active{
                background-image: url(svg-bg-uri($svg-search, #fff));
                background-color: $second-color;
            }

            @include RWD(tablet){
                display: none;
            }
            &--rwd{
                @extend .toggle-collapsing-bar;
                
                width: 64px;
                height: 60px;
                border: none;
                display: none;
                background: url(svg-bg-uri($svg-search, #fff)) no-repeat;
                background-position: 50%;
                background-size: 35px 35px;
                background-color: $core-color;
                margin-left: 5px;
                margin-right: 5px;

                &:hover, &:focus{
                    background-color: $accent-color;
                };

                &.active{
                    background-image: url(svg-bg-uri($svg-dmnt-cross, #fff));
                }

                @include RWD(tablet){
                    display: inline-block;
                }
            }
        }
    }

    // Region nav collapsed
    .region-nav-collapsed{
        display: none;
        padding: 10px;
        background-color: $second-color;
        
        #main-search{
            display: flex;
            height: 50px;

            .container{
                display: flex;
            }
            form{
                text-align: center;
                flex-grow: 1;
                
                input{
                    display: inline-block;
                    max-width: 720px;
                    width: calc(100% - 140px);
                    padding-left: 10px;
                    height: 50px;
                    font-size: 1.6rem;
                    background-color: #fff;
                    border: none;
                    color: #636161;
                    position: relative;
                    top: -1px;
                }
                button{
                    display: inline-block;
                    width: 130px;
                    line-height: 5rem;
                    font-size: 1.6rem;
                    text-align: center;
                    height: 50px;
                    color: #636161;
                    border: 1px solid #fff;
                    background-color: #fff;

                    &:hover, &:focus{
                        background-color: transparent;
                        color: #fff;
                    };
                }
            }

            .close{
                width: 50px;
                height: 50px;
                border: 1px solid #fff;
                background: white url(svg-bg-uri($svg-dmnt-cross, #636161)) no-repeat;
                background-size: 20px;
                background-position: 50% 50%;

                &:hover, &:focus{
                    background: transparent url(svg-bg-uri($svg-dmnt-cross, #fff)) no-repeat;                    
                    background-size: 20px;
                    background-position: 50% 50%;
                    };
            }   
        }

        @include RWD(mobile){
            #main-search {
                padding: 0 10px;
                form{
                    width: 100%;
                }
                .close{
                    display: none;
                }
            }    
        }
    }

    // Region nav overlayed
    & + .region-nav-overlayed{
        position: fixed;
        z-index: 10;
        left: 100%;
        top: 0;
        width: 300px;
        height: 100%;
        
        #main-menu-rwd{
            @extend %molecule-menu-rwd--cms;

            .sub-toggler{
                display: block;
            }
        }

        .back, .close{
            position: absolute;
            z-index: 4;
            top: 10px;
            height: 30px;
            width: 30px;
            opacity: 1;
            transition: all, 0.5s;

            background-repeat: no-repeat;
            background-position: 50%;
            background-size: 20px;
            background-color: transparent;

            &:hover, &:focus{
                opacity: 0.5;
            }
        }

        .back{
            display: none;
            left: 10px;
            background-image: url(svg-bg-uri($svg-arrow-left, #fff));
            background-size: 12px 20px;
        }
        .close{
            right: 10px;
            background-image: url(svg-bg-uri($svg-dmnt-cross, #fff));
            
        }
        
        &.opened{
            display: block;
        }


        @media print {
            display: none;
        }

    }
}