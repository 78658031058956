%atom-btn-int{ 
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0px 28px;
    height: 50px;
    text-decoration: none;
    position: relative;
    cursor: pointer;

    .btn-text{
        position: relative;
        color: #FFFFFF;
        font-weight: 500;
        font-size: 16px;
        font-size: 1.6rem;
    }
    .flexbox{
        width: 100%;
        z-index: 1;
    }

    &--inverted{
        @extend %atom-btn-int;
        background-color: $second-color;
        color: #fff;
        &:hover, &:focus{
            background-color: $core-color;
        }
    }
    &--core{
        @extend %atom-btn-int;
        background-color: $core-color;
        color: #fff;
        &:hover, &:focus{
            background-color: $second-color;
        }
    }
    &--white{
        @extend %atom-btn-int;
        background-color: #FFFFFF;
        .btn-text{
            color: #222222;
        }
        &:hover, &:focus{
            background-color: $second-color;
            .btn-text{
                color: #FFFFFF;
            }
        }
    }
}

//~#BUTTONS
.btn-int--core{
    @extend %atom-btn-int--core;
}

.btn-int--inverted{
    @extend %atom-btn-int--inverted;
}

.btn-int--white{
    @extend %atom-btn-int--white;
}
//#BUTTONS