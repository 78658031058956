%atom-link-rte{
    text-decoration: underline;
    transition: color, 0.5s;
    color: inherit;
    color: $core-color;
        
    &:hover, &:focus{
        color: #595959;
        text-decoration: underline;
    }

    &.external-link{
        &:after{
            content: '*';
            vertical-align: super;
            font-size: smaller;
        }
    }
}
