$path: '../fonts';

// Work Sans


    // Light
    @font-face {
        font-family: 'WorkSans';
        src: url('#{$path}/WorkSans-Light.woff') format('woff');
        font-weight: 300;
        font-style: normal;
    }

    // Regular
    @font-face {
        font-family: 'WorkSans';
        src: url('#{$path}/WorkSans-Regular.woff') format('woff');
        font-weight: 400;
        font-style: normal;
    }

    // Medium
    @font-face {
        font-family: 'WorkSans';
        src: url('#{$path}/WorkSans-Medium.woff') format('woff');
        font-weight: 500;
        font-style: normal;
    }

    //SemiBold
    @font-face {
        font-family: 'WorkSans';
        src: url('#{$path}/WorkSans-SemiBold.woff') format('woff');
        font-weight: 600;
        font-style: normal;
    }

// Montserrat
    // Regular
    @font-face {
        font-family: 'Montserrat';
        src: url('#{$path}/Montserrat-Regular.woff') format('woff');
        font-weight: 400;
        font-style: normal;
    }

