%molecule-main-menu--cms{
    display: flex;
    align-items: center;
    
    & > li{
        position: relative;
        display: block;
        flex-grow: 1;
        & > a{
            color: #201f1f;
            padding: 10px 11px;
            height: 48px;
            text-decoration: none;
            text-transform: uppercase;
            font-size: 2rem;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid #A6A6A6;

        }

        &:first-child{
            flex-grow: 0;

            &>a{
                width: 48px;
                height: 48px;
                text-indent: -9999px;
                overflow:hidden;
                position: relative;

                &:after{
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 48px;
                    height: 48px;
                    background-image: url(svg-bg-uri($svg-home, #201f1f));
                    background-repeat: no-repeat;
                    background-size: 30px 30px;
                    background-position: 50%;
                }

                &:hover, &:focus{
                    &:after{
                        background-image: url(svg-bg-uri($svg-home, #fff));
                    }
                }
            }


        }

        &:hover, &:focus{

            & > a{
                color: #fff;
                padding: 10px;
                background-color: $second-color;
                border: 1px solid #fff;
            }
            .submenu{
                display: block;
            }
        }
    }

    .submenu{
        display: none;
        position: absolute;
        bottom: 0px;
        left: 0;
        z-index: 10;
        transform: translateY(100%);
        min-width: 283px;
        width: 100%;
        padding: 0 20px;
        background-color: $second-color;
        border: 1px solid #fff;

        &.tab-opened{
            display: block;
        }

        li{
            border-bottom: 1px solid #91CEC5;
            a{
                display: block;
                padding: 15px 0;
                font-size: 2rem;
                color: #fff;
                text-transform: uppercase;
                text-decoration: none;

                &:hover, &:focus{
                    color: #17403a;
                };
            }

            &:last-child{
                border-bottom: 0;
            }
        }
    }
}