html{
    font-size: 62.5%;
    font-family: $main-font;
    font-weight: 400;

    body{
        overflow-x: hidden;
    }

    input, select, textarea{
        font-family: $main-font;
    }

    &.no-scroll{
        height: 100%;
        overflow: hidden;
    }
}
#main-content{
    overflow: hidden;
}
.container{
    @extend %container;
}
// Selection de texte
::selection{
    background-color: $second-color;
    color: #FFFFFF;
}
::-moz-selection{
    background-color: $second-color;
    color: #FFFFFF;
}

// Placeholders
::-webkit-input-placeholder {
    opacity: 1 !important;
}
:-moz-placeholder{ 
    opacity: 1 !important;
} 
::-moz-placeholder { 
    opacity: 1 !important;
}
:-ms-input-placeholder {  
opacity: 1 !important;
}

.element-invisible, .hidden{
    display: none;
}

//Menu RWD
#theme_cms > *{
    transition: all 0.5s ease;
}
.rwd-menu-opened #theme_cms > * {
    transform: translateX(-300px);
}

#popup{
    background: rgba(0,0,0,0.5);
    position: fixed;
    z-index: 11;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .popup-content{
        background-color: #fff;
        padding: 30px;
        position: relative;
        max-width: 95vw;
        h2{
            @extend %text-h2;
        }
        h3{
            @extend %text-h3;
            margin-bottom: 10px;
        }
        .close{
            position: absolute;
            top: 10px;
            right: 10px;
            height: 20px;
            width: 20px;
            background-image: url(svg-bg-uri($svg-dmnt-cross, #595959));
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50%;
        }
    }
}

#popup_login{
    width: 800px;
    max-width: 100%;
}

#popup_login,
#page-login{
    text-align: center;
    h2{
        margin-bottom: 15px;
    }
    & > div {
        margin-bottom: 20px;
        &:last-child{
            margin-bottom: 0;
        }

        .form-group{
            margin-bottom: 10px;

            label, input{
                display: block;
                width: 500px;
                max-width: 100%;
                margin: 0 auto;
                text-align: left;
            }

            label{
                line-height: 3.5rem;
                text-transform: uppercase;
                font-size: 1.5rem;
                font-weight: 500;
            }
            input{
                height: 35px;
                padding-left: 15px;
            }
        }

        button, a{
            @extend %atom-btn-int--inverted;
        }
    }
}
#login_ajax button{
    @extend %atom-btn-int--inverted;
}