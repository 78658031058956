%atom-ul-rte{
    padding-left: 50px;
    list-style: none;

    @include RWD(mobile){
        padding-left: 20px;
    }
    li{
        margin-bottom: $margin-p;
        position: relative;
        &:before{
            content: '';
            height: 7px;
            width: 7px;
            background-color: $second-color;
            border-radius: 500px;
            margin-right: 6px;
            display: block;
            position: absolute;
            left: -15px;
            top: 7px;
        }
    }
}

%atom-ol-rte{
    padding-left: 50px;
    list-style: none;
    counter-reset: item;

    @include RWD(mobile){
        padding-left: 20px;
    }
    li{
        margin-bottom: $margin-p;
        position: relative;
        &:before{
            content: counter(item) ". ";
            counter-increment: item;
            color: $second-color;
            display: block;
            position: absolute;
            left: -20px;
            top: 0px;
            font-weight: 600;
        }
    }
}